import { defineAsyncComponent } from 'vue'

const externalComponents = {
  CategoryList: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external_category-list" */ '@/components/external/CategoryList.vue')),
  ChangeStore: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external_change-store" */ '@/components/external/ChangeStore.vue')),
  DefaultBottomNavigation: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external_default-bottom-navigation" */ '@/components/external/DefaultBottomNavigation.vue')),
  DefaultFooter: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external_default-footer" */ '@/components/external/DefaultFooter.vue')),
  DefaultHeader: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external_default-header" */ '@/components/external/DefaultHeader.vue')),
  DefaultHeaderMenu: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external_default-header-menu" */ '@/components/external/DefaultHeaderMenu.vue')),
  MenuLink: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external_menu-link" */ '@/components/external/MenuLink.vue')),
  ProductCard: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external_product-card" */ '@/components/external/ProductCard.vue')),
  ProductDetailEndpoint: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external-product-detail_product-detail-endpoint" */ '@/components/external/product-detail/ProductDetailEndpoint.vue')),
  ProductDetailInstallment: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external-product-detail_product-detail-installment" */ '@/components/external/product-detail/ProductDetailInstallment.vue')),
  ProductDetailLocation: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external-product-detail_product-detail-location" */ '@/components/external/product-detail/ProductDetailLocation.vue')),
  ProductDetailStickyBottom: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external-product-detail_product-detail-sticky-bottom" */ '@/components/external/product-detail/ProductDetailStickyBottom.vue')),
  ProductList: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external_product-list" */ '@/components/external/ProductList.vue')),
  ProductListDesktop: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external_product-list-desktop" */ '@/components/external/ProductListDesktop.vue')),
  ProductListMobile: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external_product-list-mobile" */ '@/components/external/ProductListMobile.vue')),
  ProductStatusList: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external_product-status-list" */ '@/components/external/ProductStatusList.vue')),
  ProductVariantList: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external_product-variant-list" */ '@/components/external/ProductVariantList.vue')),
  PurchaseOrderList: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external_purchase-order-list" */ '@/components/external/PurchaseOrderList.vue')),
  QuickDashboard: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external_quick-dashboard" */ '@/components/external/QuickDashboard.vue')),
  QuickFilter: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external_quick-filter" */ '@/components/external/QuickFilter.vue')),
  QuickFilterFull: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-external_quick-filter-full" */ '@/components/external/QuickFilterFull.vue'))
}

const internalComponents = {
  CreateMultiplePurchaseOrderModal: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-internal-purchase-order-modal_create-multiple-purchase-order-modal" */ '@/components/internal/purchase-order/modal/CreateMultiplePurchaseOrderModal.vue')),
  ProductNameWithImage: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-internal_product-name-with-image" */ '@/components/internal/ProductNameWithImage.vue')),
  ProductReplacementModal: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-internal-purchase-order-modal_product-replacement-modal" */ '@/components/internal/purchase-order/modal/ProductReplacementModal.vue')),
  PurchaseOrderAction: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-internal-purchase-order_purchase-order-action" */ '@/components/internal/purchase-order/PurchaseOrderAction.vue')),
  PurchaseOrderAlert: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-internal-purchase-order_purchase-order-alert" */ '@/components/internal/purchase-order/PurchaseOrderAlert.vue')),
  PurchaseOrderStatus: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-internal-purchase-order_purchase-order-status" */ '@/components/internal/purchase-order/PurchaseOrderStatus.vue')),
  PurchaseOrderStoreBadge: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-internal-purchase-order_purchase-order-store-badge" */ '@/components/internal/purchase-order/PurchaseOrderStoreBadge.vue')),
  PurchaseOrderTable: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-internal-purchase-order_purchase-order-table" */ '@/components/internal/purchase-order/PurchaseOrderTable.vue')),
  PurchaseOrderTableInnerRow: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-internal-purchase-order_purchase-order-table-inner-row" */ '@/components/internal/purchase-order/PurchaseOrderTableInnerRow.vue')),
  PurchaseOrderTabs: defineAsyncComponent(() => import(
    /* webpackChunkName: "c-internal-purchase-order_purchase-order-tabs" */ '@/components/internal/purchase-order/PurchaseOrderTabs.vue'))
}

export default {
  ...externalComponents,
  ...internalComponents
}
