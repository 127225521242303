const size = {
  XXS: 50,
  XS: 100,
  S: 125,
  M: 150,
  L: 175,
  XL: 250,
  XXL: 300
}
const defaultSorter = (multiple = 1) => {
  return {
    sorter: {
      multiple
    }
  }
}
const defaultFilter = {
  filters: [],
  filterMode: 'menu', // menu | tree
  filterSearch: true,
  filterMultiple: true
  // onFilter: (value, record) => record.name.startsWith(value),
}
const defaultColumns = {
  status: {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: size.M
  },
  productName: {
    title: 'Produk',
    dataIndex: 'name',
    key: 'name',
    width: size.XL,
    ...defaultSorter(),
    ...defaultFilter
  },
  supplierName: {
    title: 'Supplier',
    key: 'supplier.name',
    width: size.M,
    ...defaultSorter(),
    ...defaultFilter
  },
  warehouseLocation: {
    title: 'Gudang',
    key: 'warehouse',
    width: size.M,
    ...defaultSorter(),
    ...defaultFilter
  },
  storeLocationGroup: {
    title: 'Lokasi',
    key: 'store.locationGroup',
    width: size.M,
    ...defaultSorter(),
    ...defaultFilter
  },
  purchaseCount: {
    title: 'Jumlah jenis produk',
    key: 'purchaseCount',
    width: size.XL
  },
  totalRequestedQuantity: {
    title: 'Total stok dipesan admin',
    key: 'totalRequestedQuantity',
    width: size.S
  },
  totalAmount: {
    title: 'Total harga',
    key: 'totalAmount',
    width: size.M
  },
  supplierInput: {
    title: 'Pilih supplier',
    key: 'supplierInput',
    width: 400
  },
  priceInput: {
    title: 'Harga supplier',
    key: 'priceInput',
    width: size.M
  },
  quantityInput: {
    title: 'Memesan',
    key: 'quantityInput',
    width: size.M
  },
  assignedQuantityInput: {
    title: 'Oper',
    key: 'assignedQuantityInput',
    width: size.M
  },
  shippingNoteInput: {
    title: 'No. Nota',
    key: 'shippingNote',
    width: size.M,
    ...defaultSorter(),
    ...defaultFilter
  },
  store: {
    title: 'Toko',
    key: 'store.code',
    width: size.M,
    ...defaultSorter(),
    ...defaultFilter
  },
  quantity: {
    title: 'Stok dipesan',
    dataIndex: 'quantity',
    key: 'quantity',
    width: size.S
  },
  requestedQuantity: {
    title: 'Stok dipesan admin',
    dataIndex: 'requestedQuantity',
    key: 'requestedQuantity',
    width: size.S
  },
  assignedQuantity: {
    title: 'Stok dialokasi admin',
    dataIndex: 'assignedQuantity',
    key: 'assignedQuantity',
    width: size.S
  },
  actualQuantity: {
    title: 'Stok masuk akhir',
    dataIndex: 'actualQuantity',
    key: 'actualQuantity',
    width: size.S
  },
  currentStock: {
    title: 'Stok sekarang',
    dataIndex: 'currentStock',
    key: 'currentStock',
    width: size.S,
    sorter: (a, b) => a.currentStock - b.currentStock
  },
  warehouseStock: {
    title: 'Stok di gudang',
    key: 'warehouse.stockLeft',
    width: size.S,
    sorter: (a, b) => a.warehouse.stockLeft - b.warehouse.stockLeft
  },
  warehousePrice: {
    title: 'Harga di gudang',
    key: 'warehousePrice',
    width: size.M
  },
  price: {
    title: 'Harga satuan',
    key: 'price',
    width: size.M
  },
  subtotal: {
    title: 'Subtotal',
    key: 'subtotal',
    width: size.M
  },
  rejectReason: {
    title: 'Alasan dibatalkan',
    key: 'rejectReason',
    dataIndex: 'rejectReason',
    width: size.M
  },
  shippingNote: {
    title: 'No. Nota',
    key: 'shippingNote',
    dataIndex: 'shippingNote',
    width: size.M,
    ...defaultFilter
  },
  notes: {
    title: 'Catatan',
    key: 'notes',
    dataIndex: 'notes',
    width: size.M
  },
  orderToSupplierDate: {
    title: 'Tanggal pesan ke Supplier',
    key: 'orderToSupplierDate',
    dataIndex: 'orderToSupplierDate',
    width: size.M,
    ...defaultSorter()
  },
  action: {
    title: 'Aksi',
    key: 'action',
    fixed: 'right'
  },
  innerAction: {
    title: 'Aksi',
    key: 'action'
  },
  createdDate: {
    title: 'Tanggal dibuat',
    key: 'createdDate',
    width: size.L,
    ...defaultSorter()
  },
  updatedDate: {
    title: 'Tanggal diubah',
    key: 'updatedDate',
    width: size.L,
    ...defaultSorter()
  },
  more: {
    title: '',
    key: 'more',
    fixed: 'right',
    width: size.XXS
  },
  empty: (title, {
    key,
    width
  } = {}) => ({
    title: title || '-',
    key: key || 'empty',
    width: width || size.M
  })
}

export default {
  purchaseOrder: {
    warehouseTabs: [
      {
        value: 'PENDING',
        text: 'Pesanan baru',
        statuses: ['PENDING'],
        groupBy: [],
        columns: [
          defaultColumns.productName,
          defaultColumns.warehouseLocation,
          defaultColumns.quantity,
          defaultColumns.notes,
          defaultColumns.currentStock,
          defaultColumns.supplierInput,
          defaultColumns.priceInput,
          defaultColumns.quantityInput,
          defaultColumns.createdDate,
          defaultColumns.updatedDate,
          defaultColumns.action,
          defaultColumns.more
        ],
        innerColumns: []
      },
      {
        value: 'OUT_OF_STOCK',
        text: 'Out of Stock',
        statuses: ['OUT_OF_STOCK'],
        groupBy: [],
        columns: [
          defaultColumns.productName,
          defaultColumns.warehouseLocation,
          defaultColumns.quantity,
          defaultColumns.notes,
          defaultColumns.currentStock,
          defaultColumns.createdDate,
          defaultColumns.updatedDate,
          defaultColumns.action,
          defaultColumns.more
        ],
        innerColumns: []
      },
      {
        value: 'WAITING_FOR_WA',
        text: 'Pesan ke Supplier',
        statuses: ['WAITING_FOR_WA'],
        groupBy: ['status', 'supplier.name'],
        columns: [
          defaultColumns.status,
          defaultColumns.supplierName,
          defaultColumns.purchaseCount,
          defaultColumns.totalRequestedQuantity,
          defaultColumns.empty(defaultColumns.price.title),
          defaultColumns.totalAmount,
          defaultColumns.empty(defaultColumns.createdDate.title, { width: defaultColumns.createdDate.width }),
          defaultColumns.empty(defaultColumns.updatedDate.title, { width: defaultColumns.createdDate.width }),
          defaultColumns.empty('', { width: size.M * 2 }),
          defaultColumns.innerAction,
          defaultColumns.empty('', { width: defaultColumns.more.width })
        ],
        innerColumns: [
          defaultColumns.warehouseLocation,
          defaultColumns.productName,
          defaultColumns.requestedQuantity,
          defaultColumns.price,
          defaultColumns.subtotal,
          defaultColumns.createdDate,
          defaultColumns.updatedDate,
          defaultColumns.empty('', { width: size.M * 2 }),
          defaultColumns.innerAction,
          defaultColumns.more
        ]
      },
      {
        value: 'WAITING_RECEIVE',
        text: 'Menunggu Barang',
        statuses: ['WAITING_RECEIVE'],
        groupBy: [],
        columns: [
          defaultColumns.productName,
          defaultColumns.warehouseLocation,
          defaultColumns.supplierName,
          defaultColumns.quantity,
          defaultColumns.requestedQuantity,
          defaultColumns.actualQuantity,
          defaultColumns.price,
          defaultColumns.subtotal,
          defaultColumns.createdDate,
          defaultColumns.orderToSupplierDate,
          defaultColumns.updatedDate,
          defaultColumns.action,
          defaultColumns.more
        ],
        innerColumns: []
      },
      {
        value: 'RECEIVED',
        text: 'Barang diterima oleh Gudang',
        statuses: ['RECEIVED'],
        groupBy: [],
        columns: [
          defaultColumns.productName,
          defaultColumns.warehouseLocation,
          defaultColumns.supplierName,
          defaultColumns.price,
          defaultColumns.quantity,
          defaultColumns.requestedQuantity,
          defaultColumns.actualQuantity,
          defaultColumns.createdDate,
          defaultColumns.updatedDate,
          defaultColumns.action,
          defaultColumns.more
        ],
        innerColumns: []
      },
      {
        value: 'COMPLETED',
        text: 'Selesai',
        statuses: ['COMPLETED'],
        groupBy: [],
        columns: [
          defaultColumns.productName,
          defaultColumns.warehouseLocation,
          defaultColumns.supplierName,
          defaultColumns.price,
          defaultColumns.quantity,
          defaultColumns.requestedQuantity,
          defaultColumns.actualQuantity,
          defaultColumns.createdDate,
          defaultColumns.updatedDate,
          defaultColumns.more
        ],
        innerColumns: []
      },
      {
        value: 'REJECTED',
        text: 'Dibatalkan',
        statuses: ['REJECTED'],
        groupBy: [],
        columns: [
          defaultColumns.productName,
          defaultColumns.warehouseLocation,
          defaultColumns.supplierName,
          defaultColumns.price,
          defaultColumns.quantity,
          defaultColumns.requestedQuantity,
          defaultColumns.actualQuantity,
          defaultColumns.rejectReason,
          defaultColumns.createdDate,
          defaultColumns.updatedDate,
          defaultColumns.action,
          defaultColumns.more
        ],
        innerColumns: []
      }
    ],
    storeTabs: [
      {
        value: 'PENDING',
        text: 'Pesanan baru',
        statuses: ['PENDING'],
        groupBy: [],
        columns: [
          defaultColumns.productName,
          defaultColumns.store,
          defaultColumns.storeLocationGroup,
          defaultColumns.quantity,
          defaultColumns.currentStock,
          defaultColumns.warehouseStock,
          defaultColumns.warehousePrice,
          defaultColumns.assignedQuantityInput,
          defaultColumns.shippingNoteInput,
          defaultColumns.notes,
          defaultColumns.createdDate,
          defaultColumns.updatedDate,
          defaultColumns.action,
          defaultColumns.more
        ],
        innerColumns: []
      },
      {
        value: 'WAITING_RECEIVE',
        text: 'Menunggu Barang tiba di Toko',
        statuses: ['WAITING_RECEIVE'],
        groupBy: [],
        columns: [
          defaultColumns.productName,
          defaultColumns.store,
          defaultColumns.storeLocationGroup,
          defaultColumns.quantity,
          defaultColumns.assignedQuantity,
          defaultColumns.warehousePrice,
          defaultColumns.shippingNote,
          defaultColumns.notes,
          defaultColumns.createdDate,
          defaultColumns.updatedDate,
          defaultColumns.action,
          defaultColumns.more
        ],
        innerColumns: []
      },
      {
        value: 'COMPLETED',
        text: 'Selesai',
        statuses: ['COMPLETED'],
        groupBy: [],
        columns: [
          defaultColumns.productName,
          defaultColumns.store,
          defaultColumns.storeLocationGroup,
          defaultColumns.quantity,
          defaultColumns.assignedQuantity,
          defaultColumns.actualQuantity,
          defaultColumns.notes,
          defaultColumns.createdDate,
          defaultColumns.updatedDate,
          defaultColumns.more
        ],
        innerColumns: []
      },
      {
        value: 'REJECTED',
        text: 'Dibatalkan',
        statuses: ['REJECTED'],
        groupBy: [],
        columns: [
          defaultColumns.productName,
          defaultColumns.store,
          defaultColumns.storeLocationGroup,
          defaultColumns.quantity,
          defaultColumns.assignedQuantity,
          defaultColumns.actualQuantity,
          defaultColumns.notes,
          defaultColumns.rejectReason,
          defaultColumns.createdDate,
          defaultColumns.updatedDate,
          defaultColumns.action,
          defaultColumns.more
        ],
        innerColumns: []
      }
    ],
    status: {
      PENDING: 'Pending',
      WAITING_FOR_WA: 'Waiting for WhatsApp',
      WAITING_RECEIVE: 'Waiting Receive',
      RECEIVED: 'Received',
      DISTRIBUTING: 'Distributing',
      COMPLETED: 'Completed',
      REJECTED: 'Rejected',
      OUT_OF_STOCK: 'Out of Stock'
    }
  }
}
