export default {
  installments: {
    ccInstallments: [
      // {
      //   paymentMethod: 'BCA_BLIBLI',
      //   label: 'BCA Blibli Mastercard',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//bca-blibli.png'
      // },
      // {
      //   paymentMethod: 'BCA',
      //   label: 'BCA',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//bca.png'
      // },
      // {
      //   paymentMethod: 'BJB',
      //   label: 'BJB',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//bjb.png'
      // },
      // {
      //   paymentMethod: 'BNI',
      //   label: 'BNI',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//bni.png'
      // },
      {
        paymentMethod: 'BRI',
        label: 'BRI',
        installmentFeePromo: false,
        logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//bri.png',
        configs: [
          {
            month: 3,
            adminFeeAmount: 0,
            interestPercentage: 0
          },
          {
            month: 6,
            adminFeeAmount: 0,
            interestPercentage: 0
          },
          {
            month: 12,
            adminFeeAmount: 0,
            interestPercentage: 0
          },
          {
            month: 18,
            adminFeeAmount: 0,
            interestPercentage: 0
          }
        ]
      }
      // {
      //   paymentMethod: 'BSI',
      //   label: 'BSI',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//bsi.png'
      // },
      // {
      //   paymentMethod: 'BTPN',
      //   label: 'BTPN',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//btpn.png'
      // },
      // {
      //   paymentMethod: 'CIMB',
      //   label: 'CIMB Niaga',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//cimb-niaga.png'
      // },
      // {
      //   paymentMethod: 'DANAMON',
      //   label: 'Danamon',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//danamon.png'
      // },
      // {
      //   paymentMethod: 'DBS',
      //   label: 'Digibank by DBS',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//digibank.png'
      // },
      // {
      //   paymentMethod: 'HSBC',
      //   label: 'HSBC',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//hsbc.png'
      // },
      // {
      //   paymentMethod: 'BUKOPIN',
      //   label: 'KB Bukopin',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//bukopin.png'
      // },
      // {
      //   paymentMethod: 'MANDIRI',
      //   label: 'Mandiri',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//mandiri.png'
      // },
      // {
      //   paymentMethod: 'MAYAPADA',
      //   label: 'Mayapada',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//mayapada.png'
      // },
      // {
      //   paymentMethod: 'MAYBANK',
      //   label: 'Maybank',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//maybank.png'
      // },
      // {
      //   paymentMethod: 'MEGA',
      //   label: 'Mega',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//mega.png'
      // },
      // {
      //   paymentMethod: 'MNC',
      //   label: 'MNC',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//mnc.png'
      // },
      // {
      //   paymentMethod: 'OCBC',
      //   label: 'OCBC NISP',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//ocbc-nisp.png'
      // },
      // {
      //   paymentMethod: 'PANIN',
      //   label: 'Panin',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//panin.png'
      // },
      // {
      //   paymentMethod: 'PERMATA',
      //   label: 'Permata Bank',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//permata.png'
      // },
      // {
      //   paymentMethod: 'SINARMAS',
      //   label: 'Sinarmas',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//sinarmas.png'
      // },
      // {
      //   paymentMethod: 'UOB',
      //   label: 'UOB',
      //   installmentFeePromo: false,
      //   logo: 'https://www.static-src.com/siva/coreasset/08_2023//1692858123098//uob.png'
      // }
    ],
    nonCcInstallments: [
      {
        paymentMethod: 'HomeCreditIndonesia',
        label: 'Home Credit Indonesia',
        installmentFeePromo: false,
        logo: 'https://ik.imagekit.io/golz/megatronic/kredit/homecredit_ZycqWpVnr.png?updatedAt=1706759611049',
        configs: [
          {
            month: 5,
            title: 'Cicilan 6x, Gratis 1x',
            adminFeeAmount: 299000,
            interestPercentage: 6.52
          },
          {
            month: 8,
            title: 'Cicilan 9x, Gratis 1x',
            adminFeeAmount: 299000,
            interestPercentage: 10.03
          }
        ]
      }//,
      // {
      //   paymentMethod: 'KREDITPLUS',
      //   label: 'Kreditplus',
      //   installmentFeePromo: false,
      //   logo: 'https://ik.imagekit.io/golz/megatronic/kredit/kredit-kreditplus_9uST4Vh8V0.png?updatedAt=1706759441605'
      // },
      // {
      //   paymentMethod: 'AEON',
      //   label: 'AEON',
      //   installmentFeePromo: false,
      //   logo: 'https://ik.imagekit.io/golz/megatronic/kredit/kredit-aeon_d5aesm0Lz.png?updatedAt=1706759441451'
      // },
      // {
      //   paymentMethod: 'SPEKTRA',
      //   label: 'Spektra',
      //   installmentFeePromo: false,
      //   logo: 'https://ik.imagekit.io/golz/megatronic/kredit/kredit-spektra_rwFX8jmXz.png?updatedAt=1706759441467'
      // },
      // {
      //   paymentMethod: 'TUNAS',
      //   label: 'Mandiri Tunas Finance',
      //   installmentFeePromo: false,
      //   logo: 'https://ik.imagekit.io/golz/megatronic/kredit/kredit-tunas_mZuWHRJae.png?updatedAt=1706759441598'
      // },
      // {
      //   paymentMethod: 'AKULAKU',
      //   label: 'Akulaku',
      //   installmentFeePromo: false,
      //   logo: 'https://ik.imagekit.io/golz/megatronic/kredit/kredit-akulaku_s7LbKIV38i.png?updatedAt=1706759441451'
      // },
      // {
      //   paymentMethod: 'MEGA_ZIP',
      //   label: 'Mega Zip',
      //   installmentFeePromo: false,
      //   logo: 'https://ik.imagekit.io/golz/megatronic/kredit/kredit-megazip_vSmc7JSfo.png?updatedAt=1706759441389'
      // },
      // {
      //   paymentMethod: 'BAF',
      //   label: 'Bussan Auto Finance',
      //   installmentFeePromo: false,
      //   logo: 'https://ik.imagekit.io/golz/megatronic/kredit/kredit-baf_S1xt_TmSO.png?updatedAt=1706759441622'
      // }
    ]
  }
}
